<template>
  <div class="container-fluid" style="padding-left: 0px; padding-right: 0px;">
    <div class="pageContainer">
      <div class="row">
        <div class="col-sm-9">
          <div class="pageSubContainer">
            <div class="row">
              <div class="col-sm-1"></div>
              <div class="col-sm-10">
                <div class="row">
                  <div class="col-sm-12">
                    <h2>Home Slider</h2>
                  </div>
                </div>
                <form @submit.prevent="editHomeSlider" enctype="multipart/form-data">
                <div class="row">
                  <div class="col-sm-4">
                    <label>Title</label>
                  </div>
                  <div class="col-sm-8">
                    <input type="text" class="text" v-model="homeslider.title" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label>Sub Title (small text)</label>
                  </div>
                  <div class="col-sm-8">
                    <input type="text" class="text" v-model="homeslider.sub_title" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label>Button Text</label>
                  </div>
                  <div class="col-sm-8">
                    <input type="text" class="text" v-model="homeslider.button_text" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label>Link</label>
                  </div>
                  <div class="col-sm-8">
                    <input type="text" class="text" v-model="homeslider.button_link" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label>Position</label>
                  </div>
                  <div class="col-sm-8">
                    <input type="text" class="text" v-model="homeslider.position" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label>Image</label>
                  </div>
                  <div class="col-sm-8">
                    <div class="commerceSelect">
                      <select v-model="homeslider.leftright">
                        <option value="Left">Left</option>
                        <option value="Right">Right</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label>Text Colour</label>
                  </div>
                  <div class="col-sm-8">
                    <div class="commerceSelect">
                      <select v-model="homeslider.text_colour">
                        <option value="White">White</option>
                        <option value="Black">Black</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label>Status</label>
                  </div>
                  <div class="col-sm-8">
                    <div class="commerceSelect">
                      <select v-model="homeslider.status">
                        <option value="Draft">Draft</option>
                        <option value="Active">Active</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label>Image</label>
                  </div>
                  <div class="col-sm-8">
                    <img :src="homeslider.image" width="200" />
                    <input type="file" v-on:change="onImageChange" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label>Tile Background Image</label>
                  </div>
                  <div class="col-sm-8">
                    <img :src="homeslider.title_image" width="200" />
                    <!--<input type="file" v-on:change="onBackgroundChange" />-->
                    <vue-select-image
                      :dataImages="dataImages"
                      :w="100"
                      @onselectimage="onSelectImage">
                    </vue-select-image>
                  </div>
                </div>
                <div class="row" style="margin-top:30px;">
                  <div class="col-sm-12">
                    <hr />
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                  </div>
                  <div class="col-sm-8">
                    <button type="submit" name="submit">Save Home Slider</button>
                  </div>
                </div>
                </form>
              </div>
              <div class="col-sm-1"></div>
            </div>
          </div>
        </div>
        <div class="col-sm-3">
          <div align="right">
            <a style="margin-left:10px;" class="btnRedLineSmall" @click.prevent="deleteSlider()">Delete</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios'

export default {
  data () {
    return {
      homeslider: {},
      image: '',
      background: '',
      singleSelected: {
        id: '',
        src: '',
      },
      dataImages: [
        { id: '1', src: 'https://www.bascofinefoods.com/wp-content/uploads/2023/11/iStock-1370665851-1.jpg', alt: '' },
        { id: '2', src: 'https://www.bascofinefoods.com/wp-content/uploads/2023/10/bilbao-tile.jpg', alt: '' },
        { id: '3', src: 'https://www.bascofinefoods.com/wp-content/uploads/2023/10/andalucian-tile.jpg', alt: '' },
        { id: '4', src: 'https://www.bascofinefoods.com/wp-content/uploads/2023/05/tile-green-brick.jpg', alt: '' },
        { id: '5', src: 'https://www.bascofinefoods.com/wp-content/uploads/2023/05/tile-white-marble.jpg', alt: '' },
        { id: '6', src: 'https://www.bascofinefoods.com/wp-content/uploads/2023/05/tile-grey-concrete.jpg', alt: '' },
        { id: '7', src: 'https://www.bascofinefoods.com/wp-content/uploads/2023/05/tile-black-marble.jpg', alt: '' },
        { id: '8', src: 'https://www.bascofinefoods.com/wp-content/uploads/2023/10/bgRecipesPattern.jpg', alt: '' },
        { id: '9', src: 'https://www.bascofinefoods.com/wp-content/uploads/2023/10/basco-christmas.jpg', alt: '' },
        { id: '10', src: 'https://www.bascofinefoods.com/wp-content/uploads/2023/05/iStock-185060800-1.jpg', alt: '' },
      ]
    }
  },
  created () {
    axios
      .get(`/wholesaleplatform/wholesalesliders/${this.$route.params.id}`)
      .then((res) => {
        this.homeslider = res.data
      })
  },
  methods: {
    onSelectImage(objectImage) {
      if (!objectImage.disabled) {
        this.singleSelected = Object.assign({}, this.singleSelected, objectImage);
        this.$emit("onselectimage", objectImage);
      }
    },

    onImageChange(e){
        //console.log(e.target.files[0]);
        this.image = e.target.files[0];
    },
    editHomeSlider () {

      const formData = new FormData();

      formData.append('image_new', this.image);
      formData.append('title_image', this.singleSelected.src);
      formData.append('title', this.homeslider.title);
      formData.append('sub_title', this.homeslider.sub_title);
      formData.append('button_text', this.homeslider.button_text);
      formData.append('button_link', this.homeslider.button_link);
      formData.append('status', this.homeslider.status);
      formData.append('position', this.homeslider.position);
      formData.append('leftright', this.homeslider.leftright);
      formData.append('text_colour', this.homeslider.text_colour);
      
      axios
        .post(`/wholesaleplatform/wholesalesliders/${this.$route.params.id}`, formData)
        .then(response => (
          this.$toast.success("Wholesale Slider saved", {}),
          this.$router.push({ name: 'WholesaleHomeSliders' })
        ))
        .catch(err => console.log(err))
        // .finally(() => this.loading = false)
        
    },
    deleteSlider () {
      axios
        .delete(`/wholesaleplatform/wholesalesliders/${this.$route.params.id}`)
        .then(response => (
          this.$toast.success("Wholesale Slider deleted", {}),
          this.$router.push({ name: 'WholesaleHomeSliders' })
        ))
        .catch(err => console.log(err))
        // .finally(() => this.loading = false)
    }
  }
}
</script>
