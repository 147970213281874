<template>
    <div id="leftNavSecond">
        <nav><h5>{{ $route.meta.navName }}</h5></nav>
        <nav class="secondNav">
        <ul>
            <router-link to="/wholesale/documents"><li>Documents</li></router-link>
            <router-link to="/wholesale/topbar"><li>Top Bar</li></router-link>
            <router-link to="/wholesale/homesliders"><li>Home Sliders</li></router-link>
            <router-link to="/wholesale/menubanners"><li>Menu Banners</li></router-link>
        </ul>
        </nav>
    </div>
</template>

<script>
export default {}
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
/*
export default {
  name: 'Home',
  components: {
    HelloWorld
  }
} */
</script>
